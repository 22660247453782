/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

/* import css per angular-calendar */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* Classi per personalizzare gli alert */
button.alert-button.alert-button-cancel {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
    border-radius: 4px;
}
button.alert-button.alert-button-confirm {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 4px;
}

*{
    font-family: 'Ubuntu', sans-serif !important;
}

ion-header{
    ion-toolbar{
        .logo{
            height: 50px;
            margin-right: 20px;
        }
    }
}

.item{
    --background: rgba(255, 255, 255, 0)
}

ion-content{
    .main{
        //background-color: #F7F7F7;
        //background-image: url('./assets/img/sfondo.svg');
        //background-position: center;
        //height: 100vh;
        min-height: 100%;

        .item{
            --background: rgba(255, 255, 255, 0)
        }
    }
}

.card{
    .item-input,
    .item{
        --background: rgba(255, 255, 255, 0)
    }

    .card_header{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem;
        border-bottom: 3px solid white;
        height: 70px;
        margin-bottom: 2rem;
        background-color: #264653;
        box-shadow: 0 8px 20px -8px rgba(0, 0, 0, 0.4);

        .card_logo{
            font-size: 3rem;
            border: 3px solid white;
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            left: 30px;
            bottom: -40px;
            background-color: #264653;
            color: white;
            box-shadow: 0 8px 8px -5px rgba(0, 0, 0, 0.4);
        }

        span{
            color: white;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }

}

.card_nuova_sede{
    .card_header{
        margin-bottom: 3rem;

        .buttons{
            margin-left: 1.5rem;
        }
    }
}

// Applies a text wrap on ion options
.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
    white-space: normal;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}


ion-modal {
    --min-height: 95%;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4) !important;
}

ion-modal.modal-pausa-sportello {
    --min-height: 0;
    --height: 250px;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
ion-modal.modaleAgenda{
    --min-height: 90%;
    --min-width: 70%;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
ion-modal.modalePrenotazioneGiornaliera{
    --min-height: 90%;
    --min-width: 50%;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
ion-modal.modaleTotem{
    --min-height: 80%;
    --min-width: 50%;
    --border-radius: 0;
    --backdrop-opacity: 0.6;
    backdrop-filter: blur(5px);
    --border-radius: 50px;
}

ion-modal.modaleRimandoProgrammato{
    --min-height: 0;
    --min-width: 40%;
    --max-height: 100%;
    --border-radius: 6px;
    --backdrop-opacity: 0.6;
    backdrop-filter: blur(5px);
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.modale_ticket_pausa_cronologico{
    --min-width: 100%;
    backdrop-filter: blur(5px);
}

ion-modal.modaleStandard{
    --min-height: 'fit-content';
}

ion-modal.modaleLarge{
    --min-height: 90%;
    --min-width: 90%;
}


ion-popover{
    --width: fit-content !important;
    --padding: 10px !important;
}

input{
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
}

input:disabled{
    color: grey;
    background-color: #f8f8f8;
    border: 1px solid lightgrey;
}

/* monitor verticale 16:9 (portrait and landscape) ----------- */
@media only screen and (min-width: 1024px) and (max-width: 1080px) {
    ion-modal.modaleTotem{
        --min-height: 45%;
        --min-width: 80%;
        --border-radius: 0;
        --backdrop-opacity: 0.6;
        backdrop-filter: blur(5px);
        --border-radius: 50px;
    }
}

/*icona accordion header super admin*/
ion-accordion-group{
    ion-accordion.accordion-expanded {
        ion-item.header{
            .ion-accordion-toggle-icon {
                color: white;
            }
        }

    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 550px) and (max-width: 800px) {
    .card{
        .card_header{
            height: 120px;
        }

    }
}
/* mobiles (landscape and portrait) */
@media only screen and (min-width: 300px) and (max-width: 549px) {
    .card{
        .card_header{
            height: 150px;
        }

    }
}

:root {
  --line-bg: #111;
  --line-height: 10px;
  --light-width: 50%;
  --light-cell-number: 8;
  --light-cell-width: calc(var(--line-height) * 3);
  --light-cell-separator-width: calc(var(--line-height) * 0.1);
  /* red light #ff000 */
  --light-color-on: rgba(255,255,0,1);
  --light-color-off: rgba(255,0,0,.25);
}

.line {
  position: relative;
  width: 100%;
  height: 1rem;
  overflow: hidden;
  background: var(--line-bg);
}

.line:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: repeating-linear-gradient(
    90deg,
    var(--light-color-off),
    var(--light-color-off) var(--light-cell-width),
    var(--line-bg) var(--light-cell-width),
    var(--line-bg) calc(var(--light-cell-width) + var(--light-cell-separator-width))
  );
  background-size: 200% 200%;
}

.light {
  position: absolute;
  top: 0;
  height: 100%;
  left: calc(var(--light-width) / 2 * -1); /* hidden start */
  width: var(--light-width);
  background: linear-gradient(90deg, transparent 0%, var(--light-color-on) 45%, var(--light-color-on) 55%, transparent 100%);
  animation: KITT 2s infinite ease-in-out alternate;
}

@keyframes KITT {
  0%, 100% {
    left: calc(var(--light-width) / 2 * -1);
  }
  50% {
    left: calc(100% - var(--light-width) / 2);
  }
}

.connecting {
  height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 2rem auto;
  padding: 1rem 4rem;
  background-color: #2c2c2c;
  color: #e0e0e0;
}
